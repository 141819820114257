<template>
  <DialogContainer
    @closeDialog="closeModal"
    :show-button-close="false"
    :buttons="config.action"
    @action="closeModal"
  >
    <template v-slot:content>
      <div class="heading margin-bottom-lg-3" v-text="config.modaltitle" />

      <div
        class="body-big margin-bottom-lg-3"
        v-text="$translations.referrals.modal.description"
      />
      <div class="share-options">
        <div
          class="share-options-item"
          v-for="(v, k) of shareOptions"
          :key="k"
          @click="v.action"
        >
          <img :src="v.url" :alt="v.text" width="40px" height="40px" />
          <div class="small" v-text="v.text" />
        </div>
      </div>
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer } from '@seliaco/red-panda'
import NavigateMatchingResetMixin from '@/mixins/navigate-matching-reset-mixin'

export default {
  name: 'ShareDialog',
  components: {
    DialogContainer
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        url: '',
        title: ''
      })
    }
  },
  mixins: [NavigateMatchingResetMixin],
  data () {
    return {
      config: {
        modaltitle: this.$translations.referrals.title,
        action: [
          {
            key: 'close',
            text: this.$translations.general.close,
            type: 'outline',
            color: 'gray'
          }
        ]
      },
      title: '',
      url: '',
      shareOptions: [
        {
          url: 'https://s3.amazonaws.com/images.app.selia/whatsapp.png',
          text: 'WhatsApp',
          action: () => this.shareWhatsapp()
        },
        {
          url: 'https://s3.amazonaws.com/images.app.selia/facebook.png',
          text: 'Facebook',
          action: () => this.shareFacebook()
        },
        {
          url: 'https://s3.amazonaws.com/images.app.selia/x.png',
          text: 'X',
          action: () => this.shareTwitter()
        }
      ]
    }
  },
  created () {
    this.title = this.data.title
    this.url = this.data.url
  },
  methods: {
    shareTwitter () {
      const twitterUrl = `https://twitter.com/intent/tweet?text=${this.title}`
      window.open(twitterUrl, '_blank')
    },
    shareFacebook () {
      const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${this.url}&description=${this.title}`
      window.open(facebookUrl, '_blank')
    },
    shareWhatsapp () {
      const whatsappUrl = `https://api.whatsapp.com/send?text=${this.title}`
      window.open(whatsappUrl, '_blank')
    },
    shareWebApi () {
      navigator.share({
        text: this.title,
        url: this.url
      })
    },
    closeModal () {
      this.$globalEvent.$emit('modal/share-dialog', {
        showDialog: { shareDialog: { open: false, close: true } }
      })
    }
  }
}
</script>

<style scoped>
.share-options {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.share-options-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 100px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
    transform: scale(1.1);
  }
}
</style>
