<template>
  <div id="app" v-bind:class="platform" v-if="ready">
    <router-view />

    <!-- global components -->
    <template>
      <!-- modal -->
      <GlobalDialog
        :appointment="appointment"
        :show-dialog="showDialog"
        :survey-config="surveyConfig"
      />

      <!-- meditation player -->
      <Transition name="slide-bottom">
        <MeditationPlayer
          :player="audioPlayerSource"
          :language="$store.getters.language.lang"
          v-if="Boolean(audioPlayerSource)"
        />
      </Transition>

      <chat-events
        v-if="user?.id"
        ref="chatEvent"
        :language="language.lang"
        :userId="user.id"
        :environment="environment"
        channelName=""
        @new-message="handlerNewMessage"
        @unread-messages="handlerUnreadMessages"
      ></chat-events>

      <!-- youtube player -->
      <YouTubePlayer
        v-if="youTubePlayerSource"
        :url="youTubePlayerSource.content"
        :title="youTubePlayerSource.title"
        @close="closeYouTubePlayer"
      />
    </template>
  </div>
</template>

<script>
import { Acquisition, HomeEvent } from '@/types/events'
import {
  PastAppointments,
  MeditationPlayer,
  YouTubePlayer
} from '@seliaco/red-panda'
import '@seliaco/giant-panda-chat'

import GlobalDialog from '@/components/dialogs/GlobalDialog'
import { mapGetters } from 'vuex'
import { userStatus } from '@/types/state'
import { handleRemoveLoader } from '../public/scripts/loader'

export default {
  components: {
    GlobalDialog,
    MeditationPlayer,
    YouTubePlayer
  },
  data () {
    return {
      platform: null,
      ready: false,
      appointment: {},
      surveyConfig: {},
      showDialog: {
        reschedule: { open: Boolean, close: Boolean },
        cancel: { open: Boolean, close: Boolean },
        changeSpecialist: { open: Boolean, close: Boolean },
        bussinessBenefit: { open: Boolean, close: Boolean },
        confirmClose: { open: Boolean, close: Boolean },
        changeDate: { open: Boolean, close: Boolean, callback: Function },
        survey: { open: Boolean, close: Boolean },
        start: { open: Boolean, close: Boolean },
        schedule: { open: Boolean, close: Boolean },
        questionnaireResult: { open: Boolean, close: Boolean },
        dontLikeSpecialists: { open: Boolean, close: Boolean },
        dontFindSpecialists: { open: Boolean, close: Boolean },
        signUpSuccess: { open: Boolean, close: Boolean, variationId: Number },
        aftercallSurvey: { open: Boolean, close: Boolean },
        timezone: { open: Boolean, close: Boolean },
        flowModal: { open: Boolean, close: Boolean, data: Object },
        lowCostModal: { open: Boolean, close: Boolean, data: Object },
        checkInModal: { open: Boolean, close: Boolean, data: Object },
        sharedAccountStartModal: { open: Boolean, close: Boolean },
        sharedAccountRemoveModal: {
          open: Boolean,
          close: Boolean,
          data: Object
        },
        shareDialog: { open: Boolean, close: Boolean, data: Object }
      },
      audioPlayerSource: null,
      youTubePlayerSource: null,
      environment: null
    }
  },
  created () {
    this.environment = process.env.VUE_APP_MODE === 'PROD' ? 'PRO' : 'DEV'
  },
  mounted () {
    handleRemoveLoader()

    this.platform = this.$store.getters.platform
    this.loadEvents()

    if (this.$store.getters['auth/user']) {
      this.redirectOnboardingSlice()
      this.callSummary()
    } else {
      this.$store.watch(
        () => this.$store.getters['auth/user'],
        () => {
          this.callSummary()
        }
      )
    }

    this.ready = true

    setTimeout(() => {
      if (this.$store.getters['auth/user']) {
        Acquisition.applaunch({
          user: {
            user_id: this.$store.getters['auth/user'].id,
            phone: this.$store.getters['auth/user'].phone,
            channel: this.$route.query.utm_campaign
          }
        })
      }
    }, 300)
  },
  methods: {
    redirectOnboardingSlice () {
      const status = [
        userStatus.ON_BOARDING_FULL_NAME_COMPLETED,
        userStatus.ON_BOARDING_GENDER_COMPLETED,
        userStatus.ON_BOARDING_BIRTHDAY_COMPLETED,
        userStatus.ON_BOARDING_PHONE_COMPLETED,
        userStatus.ON_BOARDING_THERAPY_REASON_INIT,
        userStatus.ON_BOARDING_IN_PROGRESS
      ]

      if (status.includes(this.user.status)) {
        this.$router.replace(
          {
            name: 'SlideOnboarding'
          },
          () => {}
        )
      }
    },
    callSummary () {
      PastAppointments.getMyAppointments('USER', 'FACE_TO_FACE').then(
        (response) => {
          if (response.appointment && !response.appointment.virtual) {
            this.$router.push({
              name: 'Aftercall',
              params: { id: response.appointment.id }
            })
          }
        }
      )
    },
    clearQueryParams () {
      if (this.$route.query.slug) {
        return this.$router.replace({
          path: this.$route.path,
          query: {
            disableScroll: true
          }
        })
      }

      if (this.$route.query.back) {
        return this.$router.replace({
          path: this.$route.path,
          query: {
            back: this.$route.query.back
          }
        })
      }

      this.$router.replace({
        path: this.$route.path,
        query: {}
      })
    },
    closeYouTubePlayer () {
      this.youTubePlayerSource = null
      this.clearQueryParams()
    },
    loadEvents () {
      this.$globalEvent.$on('modal/reschedule', (payload) => {
        this.appointment = payload.appointment
        this.showDialog.reschedule = payload.showDialog.reschedule
      })

      this.$globalEvent.$on('modal/cancel', (payload) => {
        this.appointment = payload.appointment
        this.showDialog.cancel = payload.showDialog.cancel
      })

      this.$globalEvent.$on('modal/change-specialist', (payload) => {
        this.showDialog.changeSpecialist = payload.showDialog.changeSpecialist
      })

      this.$globalEvent.$on('modal/bussiness-benefit', (payload) => {
        this.showDialog.bussinessBenefit = payload.showDialog.bussinessBenefit
      })

      this.$globalEvent.$on('modal/confirm-close', (payload) => {
        this.showDialog.confirmClose = payload.showDialog.confirmClose
      })

      this.$globalEvent.$on('modal/change-date', (payload) => {
        this.showDialog.changeDate = payload.showDialog.ChangeDate
      })

      this.$globalEvent.$on('modal/survey', (payload) => {
        this.surveyConfig = payload.surveyConfig
        this.showDialog.survey = payload.showDialog.survey
      })

      this.$globalEvent.$on('modal/start', (payload) => {
        this.showDialog.start = payload.showDialog.start
      })

      this.$globalEvent.$on('modal/schedule', (payload) => {
        this.showDialog.schedule = payload.showDialog.schedule
      })

      this.$globalEvent.$on('modal/questionnaire-result', (payload) => {
        this.showDialog.questionnaireResult =
          payload.showDialog.questionnaireResult
      })

      this.$globalEvent.$on('modal/dont-like-specialists', (payload) => {
        this.showDialog.dontLikeSpecialists =
          payload.showDialog.dontLikeSpecialists
      })

      this.$globalEvent.$on('modal/sign-up-success', (payload) => {
        this.showDialog.signUpSuccess = payload.showDialog.signUpSuccess
      })

      this.$globalEvent.$on('modal/aftercall-survey', (payload) => {
        this.appointment = payload?.appointment
        this.showDialog.aftercallSurvey = payload.showDialog.aftercallSurvey
      })

      this.$globalEvent.$on('modal/dont-find-specialists', (payload) => {
        this.showDialog.dontFindSpecialists =
          payload.showDialog.dontFindSpecialists
      })

      this.$globalEvent.$on('meditation/open', (payload) => {
        this.audioPlayerSource = payload
      })

      this.$globalEvent.$on('meditation/atclose', (minuteAtClose) => {
        const body = {
          user: this.$store.getters['auth/user'],
          minuteAtClose
        }
        HomeEvent.Home_Meditations_Dismiss(body)
        this.audioPlayerSource = null
        this.clearQueryParams()
      })

      this.$globalEvent.$on('youtube/open', (payload) => {
        this.youTubePlayerSource = payload.video
      })

      this.$globalEvent.$on('modal/flow', (payload) => {
        this.showDialog.flowModal = payload.showDialog.flowModal
      })

      this.$globalEvent.$on('modal/low-cost', (payload) => {
        this.showDialog.lowCostModal = payload.showDialog.lowCostModal
      })

      this.$globalEvent.$on('modal/check-in-dialog', (payload) => {
        this.showDialog.checkInModal = payload.showDialog.checkInModal
      })
      this.$globalEvent.$on('modal/timezone', (payload) => {
        this.showDialog.timezone = payload.showDialog.timezone
      })
      this.$globalEvent.$on('modal/shared-account-start', (payload) => {
        this.showDialog.sharedAccountStartModal =
          payload.showDialog.sharedAccountStartModal
      })
      this.$globalEvent.$on('modal/shared-account-remove', (payload) => {
        this.showDialog.sharedAccountRemoveModal =
          payload.showDialog.sharedAccountRemoveModal
      })
      this.$globalEvent.$on('chat/disconnect', () => {
        this.$refs.chatEvent.disconnect()
      })
      this.$globalEvent.$on('modal/share-dialog', (payload) => {
        this.showDialog.shareDialog = payload.showDialog.shareDialog
      })
    },
    handlerUnreadMessages (e) {
      this.$store.commit('chat/SET_UNREAD_MESSAGES', e?.detail?.toString())
    },
    handlerNewMessage () {}
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      language: 'language'
    })
  }
}
</script>

<style lang="sass">
@import "@/styles/main"
</style>
